import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute, Params } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { Layout } from "../model/layout";

@Component({
  selector: "app-adminlayoutsdb",
  templateUrl: "./adminlayoutsdb.component.html",
  styleUrls: ["./adminlayoutsdb.component.css"]
})
export class AdminlayoutsdbComponent implements OnInit {
  COMPANYNAME: string;
  company: any;
  public layouts: any;
  constructor(public afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, public afs: AngularFirestore) { }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.COMPANYNAME = params["company"];
      console.log("thiscompanyname" + this.COMPANYNAME);
      // WL-Domains Hack
      if (
        !this.COMPANYNAME &&
        //  window.location.host.indexOf("localhost") > -1 ||
        (window.location.host.indexOf("flash-u") > -1 || window.location.host.indexOf("c172a") > -1)
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }

      // tslint:disable-next-line:no-unused-expression
      <any>this.afs
        .doc("wlcompanies/" + this.COMPANYNAME)
        .valueChanges()
        .subscribe(x => {
          if (x) {
            console.log("thiscomapnyna", this.company);
            this.company = x;
            this.company.id = this.COMPANYNAME;
          }
        });

      this.layouts = this.afs
        .collection<any>(
          "companies/" + this.COMPANYNAME + "/layouts",
          ref => ref.orderBy("datumErstellung", "desc").limit(200) // this.count
        )
        .snapshotChanges()
        .map(actions => {
          return actions.map(a => {
            console.log(a);
            var data = a.payload.doc.data() as Layout;
            const id2 = a.payload.doc.id;
            data.id2 = id2;
            return { id2, ...data };
          });
        });
    });
  }

  deletedblayout(i) {
    this.afs.doc("companies/" + this.COMPANYNAME + "/layouts/" + i.id2).delete();
  }

  loaddata(count) {
    this.layouts = this.afs
      .collection<any>(
        "companies/" + this.COMPANYNAME + "/layouts",
        ref => ref.orderBy("datumErstellung", "desc").limit(200) // this.count
      )
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          console.log(a);
          var data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          return { id2, ...data };
        });
      });

    // tslint:disable-next-line:no-unused-expression
    <any>this.afs
      .doc("wlcompanies/" + this.COMPANYNAME)
      .valueChanges()
      .subscribe(x => {
        if (x) {
          this.company = x;
          this.company.id = this.COMPANYNAME;
        }
      });
  }

  filterCategory($event) {
    console.log($event.target.value);
    this.layouts = this.afs
      .collection<any>("companies/" + this.COMPANYNAME + "/layouts", ref =>
        ref.where("Kategorie", "==", $event.target.value)
      )
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          return { id2, ...data };
        });
      });
  }

  public saveSettings() {
    // tslint:disable-next-line:no-unused-expression
    <any>this.afs.doc("wlcompanies/" + this.COMPANYNAME).update(this.company);
  }

  public savelayout(i) {
    // tslint:disable-next-line:no-unused-expression
    <any>this.afs.doc("companies/" + this.COMPANYNAME + "/layouts/" + i.id2).update(i);
  }

}
