import { Component, OnInit, Input, Output, ApplicationRef, EventEmitter, NgZone } from "@angular/core";
//import { Layout } from "../model/layout";
import { GrafikElement } from "../model/grafikelement";
import * as firebase from "firebase";
import { AngularFirestore } from "@angular/fire/firestore";
import { ImagePipe } from "../tobase64.pipe";
import { Http } from "@angular/http";
import * as JSZipUtils from "jszip-utils";
import { Utils } from "../utils/utils";
import { PhotoNrPipe } from "../photonrpipe";
import { forEach } from "@angular/router/src/utils/collection";
import { HttpClient } from "@angular/common/http";
import { Layout } from "../model/layout";
//import { promisify } from "util";
//import jimp from "jimp";
//import Jimp from 'jimp/es';

declare var Jimp;
//const jimp = new Jimp(100, 100);
///const boundGetBuffer = promisify(jimp.getBuffer.bind(jimp));

declare var saveSvgAsPng;
declare var saveAs;
declare var canvg;
declare var WebFont;

function getCanvasBlob(canvas) {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      resolve(blob);
    });
  });
}
// var canvasBlob = getCanvasBlob(canvas);
// canvasBlob.then(function(blob) {
//   // do stuff with blob
// }, function(err) {
//   console.log(err)
// });
function loadImage(url) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.addEventListener("load", e => resolve(img));
    img.addEventListener("error", () => {
      reject(new Error(`Failed to load image's URL: ${url}`));
    });
    img.src = url;
  });
}

// load the image, and append it to the element id="image-holder"
// loadImage('http://thecatapi.com/api/images/get?format=src&type=jpg&size=small')
//   .then(img => document.getElementById('image-holder').appendChild(img))
//   .catch(error => console.error(error));
//Jimp.prototype.getBufferAsync = util.promisify(Jimp.prototype.getBuffer);
function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

@Component({
  selector: "app-svglayout",
  templateUrl: "./svglayout.component.html",
  styleUrls: ["./svglayout.component.css"]
})
export class SvglayoutComponent implements OnInit {
  customerPic: string;
  @Input()
  companyname: string;
  @Input()
  counter: string;
  @Input()
  sessionID: string;
  @Output()
  layoutcreated: EventEmitter<any> = new EventEmitter();
  @Output()
  overlaycreated: EventEmitter<any> = new EventEmitter();
  @Input()
  zoom: number;
  public PHOTOFORMAT = 1.5; // TODO: ggf. variabel machen
  @Input()
  selectedLabel: any;
  @Output()
  selectedLabelChange = new EventEmitter();

  @Output()
  selectedObjectChange = new EventEmitter();
  @Input()
  selectedObject: any;

  any;
  actiontype: string;
  leftDown: boolean;
  public lastX = 0;
  public lastY = 0;
  public posX = 100;
  public posY = 100;
  public scaleX = 1;
  public scaleY = 1;
  public rotate = 0;
  public format = "querformat";
  public showWidgets = true;
  public showAuftragsnummer = false;
  public showGraphics = true;
  public showPhotos = true;
  public showTexts = true;
  public showBackground = true;
  public showDatabase = true;
  public showBackgroundRect = true;
  @Input()
  layout: Layout;
  // tslint:disable-next-line:member-ordering
  querfotopreview: any; // = "assets/beispielfotoquer.png";
  // tslint:disable-next-line:member-ordering
  hochfotopreview = []; // "assets/beispielfotohochformat.jpg";
  isFlashu = false;

  @Input() showgrid;
  countX = 110;
  countY = 68;
  gridx = Array(this.countX + 1).fill(0).map((x, i) => i);
  gridy = Array(this.countY + 1).fill(0).map((x, i) => i);

  browser: any;
  constructor(private afs: AngularFirestore, public zone: NgZone, public http: HttpClient, public applicationRef: ApplicationRef) {
    this.querfotopreview = [
      "assets/1.jpg",
      "assets/2.jpg",
      "assets/3.jpg",
      "assets/4.jpg",
      "assets/5.jpg",
      "assets/6.jpg",
      "assets/1.jpg",
      "assets/2.jpg"
    ];
    this.hochfotopreview = [
      "assets/s1.jpg",
      "assets/s2.jpg",
      "assets/s3.jpg",
      "assets/s4.jpg",
      "assets/s5.jpg",
      "assets/s1.jpg",
      "assets/s2.jpg",
      "assets/s3.jpg"
    ];

    this.browser = Utils.get_browser();
    console.log('BROWSER: ', this.browser);

  }

  ngOnInit() {
    if (this.companyname === "L4f9n7g85s9elzhqfBLT") {
      this.isFlashu = true;
    }

  }

  private measureText(pText, pFontSize, pStyle) {
    var lDiv = <any>document.createElement("div");

    document.body.appendChild(lDiv);

    if (pStyle != null) {
      lDiv.style = pStyle;
    }
    lDiv.style.fontSize = "" + pFontSize + "px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -100;
    lDiv.style.top = -100;

    lDiv.innerHTML = pText;

    var lResult = {
      width: lDiv.clientWidth,
      height: lDiv.clientHeight
    };

    document.body.removeChild(lDiv);
    lDiv = null;

    return lResult;
  }
  private getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
  }
  public textChanged() {
    // this.selectedLabel.width = this.measureText(this.selectedLabel.content, this.selectedLabel.fontsize, null).width;
    // this.selectedLabel.height = this.measureText(this.selectedLabel.content, this.selectedLabel.fontsize, null).height;
  }
  public removeObject(index) {
    this.layout.grafikelemente.splice(index, 1);
  }
  public addGrafikElement(imgurl) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imgurl;
    img.onload = event => {
      // (<any>this).width + ' ' + (<any>this).height );
      //    console.log('test ', (<any>event).path[0].width);
      console.log("addgrafikelement ", event);
      var newGrafikElement: any = {}; // new GrafikElement();
      newGrafikElement.x = 10;
      newGrafikElement.y = 100;
      newGrafikElement.scaleX = 1;
      newGrafikElement.scaleY = 1;
      newGrafikElement.width = (<any>event).target.width;
      newGrafikElement.height = (<any>event).target.height;
      newGrafikElement.rotation = 0;
      newGrafikElement.imgurl = imgurl;
      this.layout.grafikelemente.push(newGrafikElement);
    };
  }

  fotoMouseUp(event) {
    console.log("fotomouseup", event);
    this.leftDown = false;
  }

  objectMouseDown(event, actiontype, object, src = "none") {
    event.preventDefault();
    console.log("objectMouseDown src ", src);
    if (this.layout.isStrip && src == "foto") {
      return;
    }
    this.actiontype = actiontype;
    this.selectedObject = object;
    this.selectedObjectChange.emit(this.selectedObject);

    console.log("objectMouseDown actiontype ", actiontype);

    if (src === "grafik" && actiontype === "clone") {
      const clonedObject = JSON.parse(JSON.stringify(this.selectedObject));
      clonedObject.x += 100;
      clonedObject.y += 100;
      this.layout.grafikelemente.push(clonedObject);

      this.selectedObject = clonedObject;

    }

    if (src === "label") {
      this.selectedLabel = this.selectedObject;
      this.selectedObject.height = this.selectedLabel.fontsize; // warum auch immer
      this.selectedObject.width = event.target.getBBox().width;
      //
      console.log("emitlabel");
      this.selectedLabelChange.emit(this.selectedLabel);

      console.log(
        "width height: ",
        event.target.getBBox().width,
        this.selectedLabel.fontsize
      );
    }
    this.lastX = event.screenX;
    this.lastY = event.screenY;
    if (event.touches) {
      if (event.touches[0]) {
        console.log(event.touches[0]);
        this.lastX = event.touches[0].screenX;
        this.lastY = event.touches[0].screenY;
      }
    }
    this.leftDown = true;
  }

  public svgMouseMove(event) {
    // event.preventDefault();

    if (this.leftDown) {
      if (event.touches) {
        if (event.touches[0]) {
          //                console.log(event.touches[0]);
          console.log("svgMouseMove actiontype ", this.actiontype);
          event.screenX = event.touches[0].screenX;
          event.screenY = event.touches[0].screenY;
        }
      }
      if (this.actiontype === "move") {
        this.selectedObject.x += ((event.screenX - this.lastX) * 1) / this.zoom;
        this.selectedObject.y += ((event.screenY - this.lastY) * 1) / this.zoom;
      }
      if (this.actiontype === "scale") {
        let photoformat = this.selectedObject.width / this.selectedObject.height;

        this.selectedObject.width -= ((event.screenX - this.lastX) * 1) / this.zoom;
        if (this.selectedObject.isqr) {
          if (this.selectedObject.width < 190) {
            this.selectedObject.width = 190;
            this.leftDown = false;
          }
        }
        // if (this.selectedObject.imgurl) {
        //     this.selectedObject.height += (event.screenY - this.lastY) * 1 / this.zoom; // Grafiken
        //     this.selectedObject.y -= (event.screenY - this.lastY) * 1 / this.zoom / 2;
        // }
        // else {
        //   if (this.layout.format === "querformat") {
        this.selectedObject.height = this.selectedObject.width / photoformat; // Fotos haben festes Format
        this.selectedObject.y +=
          ((event.screenX - this.lastX) * 1) / this.zoom / 2;
        //   }
        //   else {
        //       this.selectedObject.height = this.selectedObject.width * 1.5; // später ggf andere formate
        //   }
        //   }

        this.selectedObject.x +=
          (((event.screenX - this.lastX) / 2) * 1) / this.zoom;
      }
      if (this.actiontype === "rotate") {
        this.selectedObject.rotation +=
          ((event.screenX - this.lastX) * 1) / this.zoom;
      }
      this.lastX = event.screenX;
      this.lastY = event.screenY;

      // this.selectedObject.height = event.target.getBBox().height;
      // this.selectedObject.width = event.target.getBBox().width;
    }

    // dreckiger Hack for Edge Browser == Opera?!
    if (this.browser.name === 'Opera') {

      this.layout.showBranding = !this.layout.showBranding;
      this.applicationRef.tick();
      this.layout.showBranding = !this.layout.showBranding;
      this.applicationRef.tick();
    }
  }

  public async GFontToDataURI(url) {
    return fetch(url) // first fecth the embed stylesheet page
      .then(resp => resp.text()) // we only need the text of it
      .then(text => {
        // now we need to parse the CSSruleSets contained
        // but chrome doesn't support styleSheets in DOMParsed docs...
        let s = document.createElement("style");
        s.innerHTML = text;
        document.head.appendChild(s);
        let styleSheet = Array.prototype.filter.call(
          document.styleSheets,
          sS => sS.ownerNode === s // make sure this the good one
        )[0];

        // this will help us to keep track of the rules and the original urls
        let FontRule = rule => {
          let src = rule.style.getPropertyValue("src");
          // tslint:disable-next-line:no-shadowed-variable
          let url = src.split("url(")[1].split(")")[0];

          return {
            rule: rule,
            src: src,
            url: url.substring(url.length - 1, 1)
          };
        };
        let fontRules = [],
          fontProms = [];

        // iterate through all the cssRules of the embed doc
        for (var r of styleSheet.cssRules) {
          let fR = FontRule(r);

          fontRules.push(fR);
          fontProms.push(
            fetch(fR.url) // fetch the actual font-file (.woff)
              .then(resp => resp.blob()).then(blob => {
                return new Promise(resolve => {
                  // we have to return it as a dataURI
                  //   because for whatever reason,
                  //   browser are afraid of blobURI in <img> too...

                  let f = new FileReader();
                  f.onload = e => resolve(f.result);
                  f.readAsDataURL(blob);
                });
              }).then(dataURL => {
                // now that we have our dataURI version,
                //  we can replace the original URI with it
                //  and we return the full rule's cssText
                return fR.rule.cssText.replace(fR.url, dataURL);
              })
          );
        }

        document.head.removeChild(s); // clean up
        return Promise.all(fontProms); // wait for all this has been done
      });
  }

  // tslint:disable-next-line:member-ordering
  public isStripLayout() {
    this.layout.isStrip = false;
    if (this.layout.photoframes.length > 0) {
      var name = this.layout.photoframes[0].name;
      console.log(name);
      var substring = "strip";
      if (name) {
        if (name.indexOf(substring) !== -1) {
          this.layout.isStrip = true;
        }
      }
    }
    console.log("isstriplayout ", this.layout.isStrip);
  }

  public generateAutoboothFile(sessionIdwithCounter): string {
    // allgemein
    var s = "";
    s += "dpi = 300\r\n";
    s += "canvas_size = " + this.layout.width + ", " + this.layout.height + "\r\n";
    s += "canvas_size_type = pixels\r\n";

    s += "drawGuidelines = no\r\n";
    s += "aspect_ratio = " + this.PHOTOFORMAT + "\r\n";
    s += "borderQuality = fast\r\n";

    this.isStripLayout();
    // strip?
    if (this.layout.isStrip) {
      s += "number_of_pics = " + this.layout.photoframes.length / 2 + "\r\n"; //
    } else {
      s += "number_of_pics = " + this.layout.photoframes.length + "\r\n";
    }

    // bisher nur beim spiegel
    if (this.layout.photoframes.length > 0) {
      if (
        this.layout.photoframes[0].width < this.layout.photoframes[0].height
      ) {
        s += "camera_orientation = vertical\r\n";
      } else {
        s += "camera_orientation = horizontal\r\n";
      }
    } else s += "camera_orientation = vertical\r\n";

    // fotos
    var i = 1;
    for (var i = 1; i < this.layout.photoframes.length + 1; i++) {
      var frame = this.layout.photoframes[i - 1];
      s +=
        "pic" +
        i +
        "_size = " +
        Math.round(frame.width) +
        ", " +
        Math.round(frame.height) +
        "\r\n";
      s += "pic" + i + "_position = " + frame.xalt + ", " + frame.yalt + "\r\n";
      s += "pic" + i + "_position_alt = " + frame.x + ", " + frame.y + "\r\n";
      s += "pic" + i + "_rotation = " + frame.rotation + "\r\n";

      // if (this.isStrip) { // strip hat offset
      //   if ((i + 1) > this.layout.photoframes.length / 2) {
      //     break;
      //   }
      // }
    }
    // overlay
    s += "logo1_xpos = 0\r\n";
    s += "logo1_ypos = 0\r\n";
    s += "logo1_width =" + this.layout.width + "\r\n";
    s += "logo1_height =" + this.layout.height + "\r\n";
    s += "logo1_rotation = 0\r\n";
    this.layout.generatedForegroundFilename =
      sessionIdwithCounter + "_foreground.png";
    s += "logo1_filename = " + this.layout.generatedForegroundFilename + "\r\n"; // TODO: nur dateiname

    if (this.layout.showBranding) {
      s += "logo2_xpos = 34\r\n";
      s += "logo2_ypos = " + (this.layout.height - 90) + "\r\n";
      if (this.layout.isStrip) {
        s += "logo2_width = 1084\r\n";
      } else {
        s += "logo2_width = 484\r\n";
      }
      s += "logo2_height = 54\r\n";
      s += "logo2_rotation = 0\r\n";
      if (this.layout.isStrip) {
        s += "logo2_filename = branding-double.png\r\n"; // TODO: nur dateiname
      } else {
        s += "logo2_filename = branding.png\r\n"; // TODO: nur dateiname
      }
    }

    var qrnr = 3;
    for (var j = 0; j < this.layout.grafikelemente.length; j++) {
      var g = this.layout.grafikelemente[j];
      if (g.isqr) {
        //      if (g.imgurl.indexOf("iVBORw0KGgoAAAANSUhEUgAAAXIAAAG8C") > -1) {  //"qrcodedummy.png"
        var r = g.height / 444;

        var x = Math.round(g.x + 10 * r);
        var y = Math.round(g.y + 47 * r);
        var w = Math.round(342 * r); var h = Math.round(342 * r);
        s += "logo" + qrnr + "_xpos = " + x + "\r\n";// TODO: ggf umrechnen
        s += "logo" + qrnr + "_ypos = " + y + "\r\n";
        s += "logo" + qrnr + "_width =" + w + "\r\n";
        s += "logo" + qrnr + "_height =" + h + "\r\n";
        s += "logo" + qrnr + "_rotation = " + g.rotation + "\r\n";
        s += "logo" + qrnr + "_filename = qrcode.png\r\n"; // TODO: nur dateiname
        qrnr++;
        // newGrafikElement.x = 10;
        // newGrafikElement.y = 100;
        // newGrafikElement.width = (<any>event).target.width;
        // newGrafikElement.height = (<any>event).target.height;
        //      newGrafikElement.rotation = 0;

      }
    }

    if (this.layout.isStrip) {
      s += "column2 = yes\r\n";
      s += "column2_colormode = default\r\n";
      s += "column2_yoffset = 0\r\n";
      s += "column2_xoffset = 601\r\n";
      s += "column2_offset_logos = no\r\n";
      s += "column2_offset_captions = no\r\n";
    } else {
      s += "column2 = no\r\n";
    }

    // ab photoboof 3.9 werden zusätzliche Angaben benötigt
    s += "trim_left = 0\r\n";
    s += "trim_right = 0\r\n";
    s += "trim_bottom = 0\r\n";
    s += "trim_top = 0\r\n";
    s += "picture_border = no\r\n";
    s += 'extraParameters = ""\r\n';
    s += "horizontal_stretch = 0\r\n";
    s += 'background_image = ""\r\n';
    s += "picture_border_thickness = 0\r\n";
    s += "color_mode = default\r\n";

    return s;
  }

  public reformatLayout(factorX: number, factorY: number) {
    for (let photo of this.layout.photoframes) {
      photo.x = photo.x * factorX;
      photo.width = photo.width * factorX;
      photo.y = photo.y * factorY;
      photo.height = photo.height * factorY;
    }
    for (let label of this.layout.labels) {
      label.x = label.x * factorX;
      label.fontsize = label.fontsize * factorX;
      label.y = label.y * factorY;
    }

    for (let g of this.layout.grafikelemente) {
      g.x = g.x * factorX;
      g.width = g.width * factorX;
      g.y = g.y * factorY;
      g.height = g.height * factorY;
    }
  }

  private getBase64Image(imgUrl) {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imgUrl;
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
      };
      img.onerror = function () {
        reject("The image could not be loaded.");
      };
    });
  }

  private urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  private async createBase64Images() {
    for (let label of this.layout.grafikelemente) {
      try {
        await this.getBase64Image(label.imgurl).then(cssRules => {
          label.imgurl = "data:image/png;base64," + <any>cssRules;
        });
      } catch (ex) {
        console.error(ex);
      }
    }
    try {
      await this.getBase64Image(this.layout.backgroundfile).then(cssRules => {
        this.layout.backgroundfile = "data:image/png;base64," + <any>cssRules;
        // console.log(
        //   "base64background: ",
        //   this.layout.backgroundfile,
        //   "\n\n",
        //   cssRules
        // );
      });
    } catch (ex) {
      console.error(ex);
    }
    // TODO vorschaubfotos

    try {
      for (var i = 0; i < this.querfotopreview.length; i++)
        await this.getBase64Image(this.querfotopreview[i]).then(cssRules => {
          this.querfotopreview[i] = "data:image/png;base64," + <any>cssRules;
        });
      for (var i = 0; i < this.hochfotopreview.length; i++)
        await this.getBase64Image(this.hochfotopreview[i]).then(cssRules => {
          this.hochfotopreview[i] = "data:image/png;base64," + <any>cssRules;
        });
    } catch (ex) {
      console.error(ex);
    }

    // wird nach erstellung auf url zurückgesetzt
    // try {
    //   await this.getBase64Image(this.layout.rahmenfile).then(cssRules => {
    //     // console.log(this.layout.backgroundfile, "\n\n", cssRules);
    //     this.layout.rahmenfile = "data:image/png;base64," + <any>cssRules;
    //   });
    // } catch (ex) {
    //   console.error(ex);
    // }
    // tslint:disable-next-line:no-shadowed-variable

    this.applicationRef.tick();
  }

  public async generateBreezeOverlay(width: number, height: number, counter) {
    console.log("generatebreezeoverlay: ", width, "x", height);
    this.counter = counter;
    // tslint:disable-next-line:no-shadowed-variable
    var sessionIdwithCounter = this.sessionID + "_" + this.counter;

    // png fuer Kunde erstellen mit Auftragsnummer
    this.showWidgets = false;
    this.showPhotos = true;
    this.showAuftragsnummer = true;
    this.showTexts = true;
    this.showGraphics = true;
    this.layout.showCut = false;
    this.showPhotos = false;
    this.showAuftragsnummer = false;

    this.applicationRef.tick();
    await this.createBase64Images(); // achtung speichert bilder
    this.applicationRef.tick();

    //  var ip = new ImagePipe(this.http); esrsetzt durch manuelles konvertieren createBase64Images
    // pipes ausführen
    this.applicationRef.tick(); // angular neu zeichnen erzwingen
    var svg = document.getElementById("svblock");
    var svgData = new XMLSerializer().serializeToString(svg);
    var canvas = <any>document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Google Fonts
    let svgNS = "http://www.w3.org/2000/svg";
    let svgDoc = new DOMParser().parseFromString(svgData, "image/svg+xml");
    let defs = svgDoc.createElementNS(svgNS, "defs");
    // tslint:disable-next-line:no-shadowed-variable
    let style = svgDoc.createElementNS(svgNS, "style");
    for (let label of this.layout.labels) {
      try {
        await this.GFontToDataURI(
          "https://fonts.googleapis.com/css?family=" + label.fontfamily
        ).then(cssRules => {
          style.innerHTML += cssRules.join("\n");
        });
      } catch (ex) {
        if (!this.sessionID) {
          this.sessionID = "backend";
        }
        this.afs.collection("log1").add({
          datetime: new Date().getTime(),
          session: this.sessionID,
          message: "save layout Kundenbild css error: , " + ex,
          typ: "error"
        });
      }
    }
    defs.appendChild(style);
    svgDoc.documentElement.appendChild(defs);
    console.log("svgDoc ", svgDoc);
    let str = new XMLSerializer().serializeToString(svgDoc.documentElement);
    let uri = URL.createObjectURL(
      new Blob([str], {
        type: "image/svg+xml"
      })
    );
    this.applicationRef.tick(); // angular neu zeichnen erzwingen

    var img = await loadImage(uri);
    this.applicationRef.tick(); // angular neu zeichnen erzwingen

    console.log("img onload");
    ctx.drawImage(img, 0, 0);

    ///////////////

    // ueberschneidungen "ausstanzen"
    //////////////////////
    var blobanr;
    for (var i = 0; i < this.layout.photoframes.length; i++) {
      this.layout.photoframes.forEach(f => {
        f.invisible = true;
      });
      this.layout.photoframes[i].invisible = false;
      // saveasblob
      this.applicationRef.tick();
      var svg2 = document.getElementById("svblock");
      var svgData2 = new XMLSerializer().serializeToString(svg2);
      var canvas2 = <any>document.getElementById("canvas");
      var ctx2 = canvas2.getContext("2d");
      ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
      let svgDoc3 = new DOMParser().parseFromString(svgData2, "image/svg+xml");
      let style3 = svgDoc.createElementNS(svgNS, "style");
      // tslint:disable-next-line:no-shadowed-variable
      for (let label of this.layout.labels) {
        try {
          // hier zickt safari: TypeError: Cross origin requests are only supported for HTTP.
          await this.GFontToDataURI(
            "https://fonts.googleapis.com/css?family=" + label.fontfamily
          ).then(cssRules => {
            style3.innerHTML += cssRules.join("\n");
          });
          // fetch("https://fonts.googleapis.com/css?family=" + label.fontfamily).then(res =>
          //   console.log("res", res.text().then(x => console.log(x)))
          // );
        } catch (ex) {
          console.error(ex);
        }
      }
      let defs3 = svgDoc.createElementNS(svgNS, "defs");
      defs3.appendChild(style3);
      svgDoc3.documentElement.appendChild(defs3);
      var str3 = new XMLSerializer().serializeToString(svgDoc3.documentElement);
      var uri3 = URL.createObjectURL(
        new Blob([str3], {
          type: "image/svg+xml"
        })
      );
      img = await loadImage(uri3); // svg

      this.applicationRef.tick();
      ctx2.drawImage(img, 0, 0);
      blobanr = await getCanvasBlob(canvas2);
      this.applicationRef.tick();

      this.layout.backgroundfile =
        (await convertBlobToBase64(blobanr).then()) + "";
      this.showBackgroundRect = false;
      await this.delay(500);
    }

    //////////////////////

    // png erstellen von Canvas
    var c = <HTMLCanvasElement>document.getElementById("canvas");
    var blob = await getCanvasBlob(c);
    var storageRef = firebase.storage().ref().child(this.companyname + "/layoutfiles/" + sessionIdwithCounter);
    var uploadTask = await storageRef.child(sessionIdwithCounter + "_breezeimage.png").put(<any>blob).then();
    this.layout.generatedForegroundBreeze = await uploadTask.ref.getDownloadURL().then();

    console.log("this.layout.generatedForegroundBreeze", this.layout.generatedForegroundBreeze);
    this.overlaycreated.emit({
      overlay: this.layout.generatedForegroundBreeze,
      thumbnail: ""
    }); // thumbnail: tnurl.downloadURL })

    ///////////////
    // let img2 = new Image();
    // img2.crossOrigin = "Anonymous";
    // img2.onload = () => {
    //   //  var img2 = await loadImage();
    //   ctx2.drawImage(img2, 0, 0);
    //   canvas2.toBlob(blobanr => {
    //     console.log("sessionIdwithCounter ", sessionIdwithCounter);
    //     console.log('blob',blobanr);
    //     var storageRefanr = firebase
    //       .storage()
    //       .ref()
    //       .child(this.companyname + "/layoutfiles/" + sessionIdwithCounter);
    //     var uploadTask = storageRefanr
    //       .child(sessionIdwithCounter + "_breezeforeground.png")
    //       .put(blobanr)
    //       .then(url3 => {
    //         console.log('url3',url3.downloadURL);
    //         this.layout.generatedForegroundBreeze = url3.downloadURL;

    //         // Breeze Thumbnail erstellen
    //         // Jimp.read(this.layout.generatedForegroundBreeze).then(lenna => {
    //         //   lenna
    //         //     .resize(this.layout.width / 80, this.layout.height / 80) // resize
    //         //     .getBuffer(Jimp.AUTO, (err, src) => {
    //         //       storageRefanr
    //         //         .child(sessionIdwithCounter + "generatedPreviewBreeze.png")
    //         //         .put(src, { contentType: "image/png" })
    //         //         .then(async tnurl => {
    //                   this.overlaycreated.emit({ overlay: url3.downloadURL, thumbnail: '' }); // thumbnail: tnurl.downloadURL });
    //         //         });
    //         //     });
    //         // });
    //       });
    //   });
    // };
    // img2.src = uri2;
  }
  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img.height);
      img.onerror = reject;
      img.src = src;
    });
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  svgbackgroundcolor = "white";
  public async generateLayoutFiles(counter, taskid = "0", companyname = null) {
    this.svgbackgroundcolor = "transparent";
    this.applicationRef.tick();

    if (companyname) {
      this.companyname = companyname;
    }
    this.isStripLayout();
    const checkImage = path =>
      new Promise(resolve => {
        const imgt = new Image();
        imgt.crossOrigin = "Anonymous";
        imgt.onload = () =>
          resolve({
            path,
            status: "ok"
          });
        imgt.onerror = () =>
          resolve({
            path,
            status: "error"
          });
        imgt.src = path;
      });
    const loadImg = async paths => await Promise.all(paths.map(checkImage));

    // dateinamen fuer base64 Zuruecksetzung sepichern
    var backgroundtemp = this.layout.backgroundfile;
    var filenames = [];
    // if (this.layout.backgroundfile) {
    //   filenames.push(this.layout.backgroundfile);
    // }
    this.layout.grafikelemente.forEach(el => {
      filenames.push(el.imgurl);
    });

    this.applicationRef.tick();
    await this.createBase64Images(); // achtung speichert bilder
    this.applicationRef.tick();

    //   await this.createBase64Images();
    this.applicationRef.tick();
    this.counter = counter;
    // bsp v1-2017-08-19_D_pau-16-i28q3oe_v1
    var datestring =
      new Date(this.layout.datumVeranstaltung).getFullYear() +
      "-" +
      Utils.str_pad(new Date(this.layout.datumVeranstaltung).getMonth() + 1) +
      "-" +
      Utils.str_pad(new Date(this.layout.datumVeranstaltung).getDate());
    var sessionIdwithCounter =
      "v1-" +
      datestring +
      "_" +
      this.layout.auftragsnummerName +
      "_" +
      this.counter;
    this.layout.id = null;
    var tempname = this.layout.auftragsnummerName;
    this.layout.auftragsnummerName =
      this.layout.auftragsnummerName + "-" + this.counter;
    this.layout.datumErstellung = new Date().getTime();
    this.layout.category = "";
    // png fuer Kunde erstellen mit Auftragsnummer
    this.showWidgets = false;
    this.showPhotos = true;
    this.showAuftragsnummer = true;
    this.showTexts = true;
    this.showGraphics = true;
    this.layout.showCut = false;

    // pipes ausführen
    this.applicationRef.tick(); // angular neu zeichnen erzwingen
    var svg = document.getElementById("svblock");
    var svgData = new XMLSerializer().serializeToString(svg);
    var canvas = <any>document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.applicationRef.tick();
    console.log('createbase64')
    await this.createBase64Images(); // achtung speichert bilder
    this.applicationRef.tick();
    console.log('fonts..')
    // Google Fonts
    let svgNS = "http://www.w3.org/2000/svg";
    let svgDoc = new DOMParser().parseFromString(svgData, "image/svg+xml");
    let defs = svgDoc.createElementNS(svgNS, "defs");
    // tslint:disable-next-line:no-shadowed-variable
    let style = svgDoc.createElementNS(svgNS, "style");
    for (let label of this.layout.labels) {
      try {
        await this.GFontToDataURI("https://fonts.googleapis.com/css?family=" + label.fontfamily).then(cssRules => { style.innerHTML += cssRules.join("\n"); });
      } catch (ex) {
        if (!this.sessionID) {
          this.sessionID = "backend";
        }
        this.afs.collection("log1").add({
          datetime: new Date().getTime(),
          session: this.sessionID,
          message: "save layout Kundenbild css error: , " + ex,
          typ: "error"
        });
      }
    }
    defs.appendChild(style);
    console.log('fonts complete')
    svgDoc.documentElement.appendChild(defs);
    // console.log("svgDoc ", svgDoc);
    console.log('serialize')
    let str = new XMLSerializer().serializeToString(svgDoc.documentElement);
    console.log('serialized')
    let uri = URL.createObjectURL(
      new Blob([str], {
        type: "image/svg+xml"
      })
    );
    console.log('lodimage', uri)

    var img = await loadImage(uri);
    console.log("img onload");
    ctx.drawImage(img, 0, 0);

    // layout für Nutzer anzeigen
    // png erstellen von Canvas
    console.log('customer image render upload')
    var c = <HTMLCanvasElement>document.getElementById("canvas");
    //   c.toBlob(blob => {
    var blob = await getCanvasBlob(c);
    var storageRef = firebase.storage().ref().child(this.companyname + "/layoutfiles/" + sessionIdwithCounter);
    var uploadTask = await storageRef.child(sessionIdwithCounter + "_customerimage.png").put(<any>blob).then();

    console.log("customer image saved");

    this.layout.generatedCustomerImage = await uploadTask.ref.getDownloadURL().then();


    if (this.layout.email) {
      var mailurl = "https://us-central1-layoutgenerator-c172a.cloudfunctions.net/sm?mail=" + this.layout.email + "&layout=" + (this.layout.generatedCustomerImage);
      console.log('sendmail', mailurl)
      this.http.post(mailurl, JSON.stringify({ email: this.layout.email, layout: this.layout.generatedCustomerImage, name: this.layout.auftragsnummerName })).subscribe(data => {
        console.log('sendmail')
      });
    }


    if (!this.sessionID) {
      this.sessionID = "backend";
    }
    this.afs.collection("log1").add({
      datetime: new Date().getTime(),
      session: this.sessionID,
      message: "save layout Kundenbild generiert , " + this.layout.generatedCustomerImage,
      typ: "debug"
    });
    ///////////////////////////////////////////////////////////////////////////////////
    // overlay maske erstellen
    console.log('overlay')
    this.showPhotos = false;
    this.showAuftragsnummer = false;
    this.applicationRef.tick(); // angular neu zeichnen erzwingen
    var svg2 = document.getElementById("svblock");
    var svgData2 = new XMLSerializer().serializeToString(svg2);
    var canvas2 = <any>document.getElementById("canvas");
    var ctx2 = canvas2.getContext("2d");
    ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
    let svgDoc2 = new DOMParser().parseFromString(svgData2, "image/svg+xml");
    let defs2 = svgDoc2.createElementNS(svgNS, "defs");
    let style2 = svgDoc2.createElementNS(svgNS, "style");
    // tslint:disable-next-line:no-shadowed-variable
    for (let label of this.layout.labels) {
      try {
        // hier zickt safari: TypeError: Cross origin requests are only supported for HTTP.
        await this.GFontToDataURI("https://fonts.googleapis.com/css?family=" + label.fontfamily).then(cssRules => {
          style2.innerHTML += cssRules.join("\n");
        });
      } catch (ex) {
        console.error(ex);
        this.afs.collection("log1").add({
          datetime: new Date().getTime(),
          session: this.sessionID,
          message: "save layout Overlay css error: , " + ex,
          typ: "error",
          browser: Utils.get_browser()
        });
      }
    }
    defs2.appendChild(style2);
    svgDoc2.documentElement.appendChild(defs2);
    // console.log("svgDoc2 ", svgDoc2);
    let str2 = new XMLSerializer().serializeToString(svgDoc2.documentElement);
    let uri2 = URL.createObjectURL(
      new Blob([str2], {
        type: "image/svg+xml"
      })
    );

    var img2 = await loadImage(uri2);
    ctx2.drawImage(img2, 0, 0);
    var blobanr = await getCanvasBlob(canvas2);

    // ueberschneidungen "ausstanzen"
    //////////////////////
    for (var i = 0; i < this.layout.photoframes.length; i++) {
      console.log('ausstanzen', i)
      this.layout.photoframes.forEach(f => {
        f.invisible = true;
      });
      this.layout.photoframes[i].invisible = false;
      // saveasblob
      this.applicationRef.tick();
      var svg2 = document.getElementById("svblock");
      var svgData2 = new XMLSerializer().serializeToString(svg2);
      var canvas2 = <any>document.getElementById("canvas");
      var ctx2 = canvas2.getContext("2d");
      ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
      let svgDoc3 = new DOMParser().parseFromString(svgData2, "image/svg+xml");
      let style3 = svgDoc2.createElementNS(svgNS, "style");
      // tslint:disable-next-line:no-shadowed-variable
      for (let label of this.layout.labels) {
        try {
          // hier zickt safari: TypeError: Cross origin requests are only supported for HTTP.
          await this.GFontToDataURI("https://fonts.googleapis.com/css?family=" + label.fontfamily).then(cssRules => {
            style3.innerHTML += cssRules.join("\n");
          });
          // fetch("https://fonts.googleapis.com/css?family=" + label.fontfamily).then(res =>
          //   console.log("res", res.text().then(x => console.log(x)))
          // );
        } catch (ex) {
          console.error(ex);
        }
      }
      let defs3 = svgDoc2.createElementNS(svgNS, "defs");
      defs3.appendChild(style3);
      svgDoc3.documentElement.appendChild(defs3);
      var str3 = new XMLSerializer().serializeToString(svgDoc3.documentElement);
      var uri3 = URL.createObjectURL(
        new Blob([str3], {
          type: "image/svg+xml"
        })
      );
      img2 = await loadImage(uri3); // svg

      this.applicationRef.tick();
      ctx2.drawImage(img2, 0, 0);
      blobanr = await getCanvasBlob(canvas2);
      this.applicationRef.tick();

      this.layout.backgroundfile = (await convertBlobToBase64(blobanr).then()) + "";
      this.showBackgroundRect = false;
      await this.delay(500);
    }

    // txt erstellen
    var uploadTask = await storageRef.child(sessionIdwithCounter + "_autobooth.txt").putString(this.generateAutoboothFile(sessionIdwithCounter)).then();
    console.log("uploadTask ");
    this.layout.generatedAutoboothfile = await uploadTask.ref.getDownloadURL().then();
    // in gstorage speichern
    // console.log(
    //   "  this.layout.generatedAutoboothfile",
    //   this.layout.generatedAutoboothfile
    // );

    var storageRefanr = firebase.storage().ref().child(this.companyname + "/layoutfiles/" + sessionIdwithCounter);
    var uploadTask = await storageRef.child(sessionIdwithCounter + "_foreground.png").put(<any>blobanr).then();
    this.layout.generatedForeground = await uploadTask.ref.getDownloadURL().then();

    //  url3 => {
    console.log(
      "   this.layout.generatedForeground",
      this.layout.generatedForeground
    );
    //  this.layout.generatedForeground = url3.downloadURL;
    this.layout.generatedForegroundFilename =
      sessionIdwithCounter + "_foreground.png";
    //   console.log("foreground ", this.layout.generatedForeground);
    this.afs.collection("log1").add({
      datetime: new Date().getTime(),
      session: this.sessionID,
      message:
        "save layout - foreground created: " + this.layout.generatedForeground,
      typ: "debug"
    });

    // Thumbnail erstellen
    console.log('thumbnail')
    //    console.log(this.layout.generatedForeground);
    await Jimp.read(this.layout.generatedForeground).then(async lenna => {
      //     console.log("lenna ", lenna);
      await lenna.resize(this.layout.width / 10, this.layout.height / 10).getBuffer(Jimp.AUTO, async (err, src) => {
        //                       .then(); // => {
        console.log('resized')
        uploadTask = await storageRef.child(sessionIdwithCounter + "generatedPreview.png").put(src, { contentType: "image/png" }).then();
        this.layout.generatedPreview = await uploadTask.ref.getDownloadURL().then();
        // Layout in Datenbank speichern
        //                        var data = JSON.parse(JSON.stringify(this.layout));
        // nicht bei ssr speichern:       this.layout.browser = Utils.get_browser();
        // Ansicht zurücksetzen
        this.showWidgets = true;
        this.showAuftragsnummer = false;
        this.showTexts = true;
        this.showGraphics = true;
        this.showPhotos = true;
        this.showWidgets = true;
        this.showBackground = true;
        this.layout.showCut = true;
        // base64 zurücksetzen
        //   console.log("resetbase64 ", backgroundtemp, filenames);
        this.layout.backgroundfile = backgroundtemp;
        i = 0;
        this.layout.grafikelemente.forEach(g => {
          g.imgurl = filenames[i];
          i++;
        });

        this.layout.photoframes.forEach(f => {
          f.invisible = false;
        });
        this.showBackgroundRect = true;
        this.isStripLayout();

        const items = this.afs.collection<any>("companies/" + this.companyname + "/userlayouts").add(JSON.parse(JSON.stringify(this.layout)));
        this.layout.auftragsnummerName = tempname;
        this.layout.auftragsnummerName2 = tempname.toLowerCase();
        this.afs.collection<any>("companies/" + this.companyname + "/userlayoutnames")
          .add({
            name: this.layout.auftragsnummerName + "-" + this.counter
          });
        console.log(
          "saved to database companies/" + this.companyname + "/userlayouts",
          this.layout
        );



        // dispo update, wenn orderid
        if (this.layout.boxkey) {
          //  https://hooks.zapier.com/hooks/catch/948490/3m5qjvz/?layout=test-17&key=agxzfm1haWxmb29nYWVyKwsSDE9yZ2FuaXphdGlvbiILZmxhc2gtdS5jb20MCxIEQ2FzZRjhxf_2Aww
          this.http.get("https://hooks.zapier.com/hooks/catch/948490/3m5qjvz/?layout=" + this.layout.auftragsnummerName + "-" + this.counter + "&key=" + this.layout.boxkey).subscribe(val => {
            console.log("zapier", val);
          });
        }


        // TODO: event emitten mit customerpic
        this.afs.collection("log1").add({
          datetime: new Date().getTime(),
          session: this.sessionID,
          message:
            "save layout - layout created: " + this.layout.auftragsnummerName,
          typ: "debug"
        });
        this.layoutcreated.emit(taskid);
      });
    });
  }
}

// {
//   "standardlayouts": {
//       "querformat": [
//           [
//               {
//         "kategorie": "Standard-Layout",
//                   "name": "Grid 2b",
//                   "x": 75,
//                   "y": 165,
//                   "width": 670,
//                   "height": 446,
//                   "psdvorlage": "https://files.flash-u.com/layout-vorlagen/grid2b.psd",
//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/grid2b.png"
//               },
//               {
//                   "x": 762,
//                   "y": 165,
//                   "width": 670,
//                   "height": 446
//               },
//               {
//                   "x": 75,
//                   "y": 628,
//                   "width": 670,
//                   "height": 446
//               },
//               {
//                   "x": 762,
//                   "y": 628,
//                   "width": 670,
//                   "height": 446
//               }
//           ],
//           [
//               {
//         "kategorie": "Standard-Layout",
//                   "name": "3x1",
//                   "x": 39,
//                   "y": 772,
//                   "width": 564,
//                   "height": 376,
//                   "psdvorlage": "https://files.flash-u.com/layout-vorlagen/3x1.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/3x1.png"
//               },
//               {
//                   "x": 638,
//                   "y": 772,
//                   "width": 564,
//                   "height": 376
//               },
//               {
//                   "x": 1237,
//                   "y": 772,
//                   "width": 564,
//                   "height": 376
//               },
//               {
//                   "x": 39,
//                   "y": 92,
//                   "width": 968,
//                   "height": 645
//               }
//           ],
//           [
//               {
//         "kategorie": "Standard-Layout",
//         "name": "Emoticon",
//                   "x": 95,
//                   "y": 141,
//                   "width": 680,
//                   "height": 453,
//                   "psdvorlage": "https://files.flash-u.com/layout-vorlagen/emoticon.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/emoticon.png"
//               },
//               {
//                   "x": 827,
//                   "y": 141,
//                   "width": 680,
//                   "height": 453
//               },
//               {
//                   "x": 95,
//                   "y": 646,
//                   "width": 680,
//                   "height": 453
//               },
//               {
//                   "x": 827,
//                   "y": 646,
//                   "width": 680,
//                   "height": 453
//               }
//           ],
//           [
//               {
//         "kategorie": "Standard-Layout",
//                   "name": "Logo rechts",
//                   "x": 36,
//                   "y": 74,
//                   "width": 812,
//                   "height": 541,
//         "psdvorlage": "https://files.flash-u.com/layout-vorlagen/logo-rechts.psd",
//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/logo-rechts.png"
//               },
//               {
//                   "x": 858,
//                   "y": 74,
//                   "width": 812,
//                   "height": 541
//               },
//               {
//                   "x": 36,
//                   "y": 625,
//                   "width": 812,
//                   "height": 541
//               },
//               {
//                   "x": 858,
//                   "y": 625,
//                   "width": 812,
//                   "height": 541
//               }
//           ],
//           [
//               {
//         "kategorie": "Standard-Layout",
//                   "name": "Logo links und rechts",
//                   "x": 177,
//                   "y": 119,
//                   "width": 730,
//                   "height": 486,
//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/logo-links-rechts.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/logo-links-rechts.png"
//               },
//               {
//                   "x": 937,
//                   "y": 119,
//                   "width": 730,
//                   "height": 486
//               },
//               {
//                   "x": 177,
//                   "y": 635,
//                   "width": 730,
//                   "height": 486
//               },
//               {
//                   "x": 937,
//                   "y": 635,
//                   "width": 730,
//                   "height": 486
//               }
//           ],
//           [
//               {
//         "kategorie": "Standard-Layout",
//                   "name": "vierer",
//                   "x": 60,
//                   "y": 37,
//                   "width": 850,
//                   "height": 566,
//         "psdvorlage": "https://files.flash-u.com/layout-vorlagen/vierer.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/vierer.png"
//               },
//               {
//                   "x": 933,
//                   "y": 37,
//                   "width": 850,
//                   "height": 566
//               },
//               {
//                   "x": 60,
//                   "y": 626,
//                   "width": 850,
//                   "height": 566
//               },
//               {
//                   "x": 933,
//                   "y": 626,
//                   "width": 850,
//                   "height": 566
//               }
//           ],
//           [
//               {
//                "kategorie": "Standard-Layout",
//         "name": "3x1 Logo klein",
//                   "x": 87,
//                   "y": 803,
//                   "width": 550,
//                   "height": 366,
//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/3x1_klein.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/3x1_klein.png"

//               },
//               {
//                   "x": 647,
//                   "y": 803,
//                   "width": 550,
//                   "height": 366
//               },
//               {
//                   "x": 1207,
//                   "y": 803,
//                   "width": 550,
//                   "height": 366
//               },
//               {
//                   "x": 87,
//                   "y": 55,
//                   "width": 1110,
//                   "height": 740
//               }
//           ],
//           [
//               {
//                 "kategorie": "Standard-Layout",
//                   "name": "Grid 2",
//                   "x": 118,
//                   "y": 118,
//                   "width": 746,
//                   "height": 497,
//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/grid2.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/grid2.png"
//               },
//               {
//                   "x": 873,
//                   "y": 118,
//                   "width": 746,
//                   "height": 497
//               },
//               {
//                   "x": 118,
//                   "y": 624,
//                   "width": 746,
//                   "height": 497
//               },
//               {
//                   "x": 873,
//                   "y": 624,
//                   "width": 746,
//                   "height": 497
//               }
//           ],
//           [
//               {
//                 "kategorie": "Standard-Layout",

//                   "name": "Fancy 4 Up",
//                   "x": 457,
//                   "y": 81,
//                   "width": 650,
//                   "height": 433,
//        "psdvorlage": "https://files.flash-u.com/layout-vorlagen/fancy-4-up.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/fancy-4-up.png"
//               },
//               {
//                   "x": 1125,
//                   "y": 81,
//                   "width": 650,
//                   "height": 433
//               },
//               {
//                   "x": 457,
//                   "y": 726,
//                   "width": 650,
//                   "height": 433
//               },
//               {
//                   "x": 1125,
//                   "y": 726,
//                   "width": 650,
//                   "height": 433
//               }
//           ],
//           [
//               {
//                 "kategorie": "Standard-Layout",

//                   "name": "Vier rotiert",
//                   "x": 202,
//                   "y": 229,
//                   "width": 670,
//                   "height": 446,
//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/vier-rotiert.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/vier-rotiert.png"
//               },
//               {
//                   "x": 882,
//                   "y": 109,
//                   "width": 670,
//                   "height": 446
//               },
//               {
//                   "x": 292,
//                   "y": 685,
//                   "width": 670,
//                   "height": 446
//               },
//               {
//                   "x": 972,
//                   "y": 565,
//                   "width": 670,
//                   "height": 446

//               }
//           ],
//           [
//               {
//                 "kategorie": "Standard-Layout",

//                   "name": "Einzelbild",
//                   "x": 0,
//                   "y": 0,
//                   "width": 1860,
//                   "height": 1240,

//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/einzelbild.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/einzelbild.png"
//               }
//           ],
//           [
//               {
//                 "kategorie": "Standard-Layout",

//                   "name": "Einzelbild mit Logo",
//                   "x": -207,
//                   "y": 0,
//                   "width": 1860,
//                   "height": 1240,

//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-logo.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-logo.png"
//               }
//       ],
//       [
//           {
//               "kategorie": "Standard-Layout",

//               "name": "Einzelbild mit Rand",
//               "x": 122,
//               "y": 87,
//               "width": 1600,
//               "height": 1066,

//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-rand.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-rand.png"
//           }
//       ]
//   ],
//   "hochformat": [
//       [
//           {
//               "kategorie": "Spiegel-Layout",

//               "name": "Spiegel",
//               "x": 114,
//               "y": 114,
//               "width": 1012,
//               "height": 1372,

//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/spiegel.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/spiegel.png"
//           }
//       ],
//       [
//               {
//                 "kategorie": "Spiegel-Layout",
//                   "name": "Spiegel-4er",
//                   "x": 59,
//                   "y": 54,
//                   "width": 532,
//                   "height": 798,
//                   "psdvorlage": "https://files.flash-u.com/layout-vorlagen/4er-Spiegel.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/4er-Spiegel.png"
//               },
//               {
//                   "x": 649,
//                   "y": 54,
//                   "width": 532,
//                   "height": 798
//               },
//               {
//                   "x": 59,
//                   "y": 990,
//                   "width": 532,
//                   "height": 798
//               },
//               {
//                   "x": 649,
//                   "y": 990,
//                   "width": 532,
//                   "height": 798
//               }
//       ],

//       [
//         {
//           "kategorie": "Spiegel-Layout",
//             "name": "Spiegel strip",
//             "x": 59,
//             "y": 54,
//             "width": 532,
//             "height": 798,
//             "psdvorlage": "https://files.flash-u.com/layout-vorlagen/4er-Spiegel.psd",

//             "pngvorlage": "https://files.flash-u.com/layout-vorlagen/4er-Spiegel.png"
//         },
//         {
//             "x": 649,
//             "y": 54,
//             "width": 532,
//             "height": 798
//         },
//         {
//             "x": 59,
//             "y": 990,
//             "width": 532,
//             "height": 798
//         },
//         {
//             "x": 649,
//             "y": 990,
//             "width": 532,
//             "height": 798
//         }
// ],

//       [
//           {
//               "kategorie": "Streifen-Layout",

//               "name": "Double strip",
//               "x": 52,
//               "y": 52,
//               "width": 535,
//               "height": 356,

//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/double-strip.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/double-strip.png"
//           },
//           {
//               "x": 52,
//               "y": 460,
//               "width": 535,
//               "height":356
//           },
//           {
//               "x": 52,
//               "y": 868,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 52,
//               "y": 1276,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 653,
//               "y": 52,
//               "width": 535,
//               "height":356
//           },
//           {
//               "x": 653,
//               "y": 460,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 653,
//               "y": 868,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 653,
//               "y": 1276,
//               "width": 535,
//               "height": 356
//           }
//       ],
//       [
//           {
//       "kategorie": "Streifen-Layout",

//               "name": "Double strip 3er",
//               "x": 52,
//               "y": 52,
//               "width": 535,
//               "height": 356,

//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/double-strip-3er.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/double-strip-3er.png"
//           },
//           {
//               "x": 52,
//               "y": 460,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 52,
//               "y": 868,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 653,
//               "y": 52,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 653,
//               "y": 460,
//               "width": 535,
//               "height": 356
//           },
//           {
//               "x": 653,
//               "y": 868,
//               "width": 535,
//               "height": 356
//           }
//       ],

//       [
//           {
//       "kategorie": "Standard-Layout",

//               "name": "2 Bilder",
//               "x": 50,
//               "y": 50,
//               "width": 1140,
//               "height": 760,

//       "psdvorlage": "https://files.flash-u.com/layout-vorlagen/2-bilder.psd",

//                   "pngvorlage": "https://files.flash-u.com/layout-vorlagen/2-bilder.png"
//           },
//           {
//               "x": 50,
//               "y": 860,
//               "width": 1140,
//               "height": 760
//           }
//       ]
//   ]
// }
// }
