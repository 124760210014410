import { Component, OnInit, ViewChild, ApplicationRef, AfterViewInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Layout } from "../model/layout";
import { SvglayoutComponent } from "../svglayout/svglayout.component";
import { Http } from "../../../node_modules/@angular/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute, Params } from "../../../node_modules/@angular/router";
import * as firebase from "firebase";
import "rxjs/add/operator/map";

@Component({
  selector: "app-backgroundworker",
  templateUrl: "./backgroundworker.component.html",
  styleUrls: ["./backgroundworker.component.css"]
})
export class BackgroundworkerComponent implements OnInit, AfterViewInit {
  public tasks;

  public COMPANYNAME = "flashu";
  public company: any;
  public layouts: any;
  public selectedlayout: Layout;
  public userlayouts: any;
  public uploads: any;
  public count: number;
  public SHOWCOUNT = 10;
  public loading = false;
  headColor: string;
  model: any;
  public counter: number;
  public sessionid: string;
  public flashboothbrowser: boolean;
  public processing: boolean;
  @ViewChild("svglayout")
  svglayout: SvglayoutComponent;

  constructor(public http: Http, public afAuth: AngularFireAuth, public afs: AngularFirestore, private activatedRoute: ActivatedRoute, public applicationRef: ApplicationRef) {
    this.activatedRoute.params.subscribe(async (params: Params) => {
      console.log("params", params);

      this.COMPANYNAME = params["company"];
      // WL-Domains Hack
      if (
        window.location.host.indexOf("localhost") > -1 ||
        window.location.host.indexOf("flash-u") > -1 ||
        window.location.host.indexOf("c172a") > -1
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }

      this.tasks = afs.collection("tasks", ref => ref.orderBy("datetime", "desc").limit(100)).snapshotChanges().map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          const id = a.payload.doc.id;
          return {
            id2,
            id,
            ...data
          };
        });
      });

      this.processing = false;

      //  this.tasks.pipe(concatMap(tasks => processTasks(tasks))).subscribe();
      // await this.tasks.subscribe(async x => {
      //   console.log("processing ", x);
      //   for (const element of x) {
      //     try {
      //       console.log("for ", this.processing, element);
      //       //    if (!this.processing) {
      //       console.log("processing for ", this.processing, element);
      //       this.processing = true;
      //       this.selectedlayout = element.layout;
      //       this.applicationRef.tick();
      //       //   // ggf. Namen abändern
      //       // //  this.increaseCounterToAvailable();
      //       this.svglayout.layout = this.selectedlayout;
      //       console.log("await generatelayoutfiles");
      //       await this.svglayout.generateLayoutFiles(element.counter, element.id, element.companyname); // await funzt nicht so wie gewollt?!
      //       await this.afs.doc("tasks/" + element.id).delete();
      //       console.log("layoutGenerated bw ", element.id);
      //       //    this.processing = false;
      //     } catch (ex) {
      //       this.afs.collection("log1").add({
      //         datetime: new Date().getTime(),
      //         session: "element " + element.id,
      //         message: "backgroundwoker error: , " + ex,
      //         typ: "error"
      //       });
      //     }
      //   }
      // });
    });
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    setInterval(() => {
      console.log("loop", this.processing);
      if (!this.processing) {
        //  setTimeout(() => {
        console.log("looking for tasks");
        firebase.firestore().collection("tasks").limit(1) //, ref => ref.orderBy("datetime", "desc").limit(1))
          .get().then(async querySnapshot => {
            await querySnapshot.forEach(async element => {
              console.log("task", element);
              this.processing = true;

              await firebase.firestore().collection("tasks/").doc(element.id).update({ state: "processing" }).then();

              // doc.data() is never undefined for query doc snapshots
              var task = element.data();
              if (task.state) {
                // if (task.state === "pending") {
                console.log(element.id, " => ", element.data());

                this.selectedlayout = element.data().layout;
                this.applicationRef.tick();
                this.svglayout.layout = this.selectedlayout;
                this.applicationRef.tick();
                console.log("await generatelayoutfiles");
                await this.svglayout.generateLayoutFiles(element.data().counter, element.id, task.companyname); // await funzt nicht so wie gewollt?!
                await firebase.firestore().collection("tasks/").doc(element.id).delete().then();

                this.processing = false;
                //  }
              }
            });
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
        //  }, 1000);
      }
    }, 6000);
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  public regenerateLayoutFiles(layout) {
    this.selectedlayout = layout;
    this.loading = true;
    this.applicationRef.tick();

    // ggf. Namen abändern
    //  this.increaseCounterToAvailable();
    this.svglayout.layout = layout;
    console.log("regenerate Layout fies: ", layout);
    this.svglayout.generateLayoutFiles(1);
  }

  public layoutGenerated(taskid) {
    this.loading = false;
  }
  public overlayCreated(event) {
    console.log("overlaycreated ", event);
    var sessionIdwithCounter = this.sessionid + this.counter;
    //  this.generateBreezeFile(sessionIdwithCounter, this.selectedlayout, this.model, event.overlay); // "https://firebasestorage.googleapis.com/v0/b/layoutgenerator-c172a.appspot.com/o/flashu%2Flayoutfiles%2Fv1_2018_04_30_kjhkhjjk_1%2Fv1_2018_04_30_kjhkhjjk_1_foreground.png?alt=media&token=17cdd00e-94a2-44bc-8417-7c24c3b2eef8");
  }
}
