import { Photoframe } from "./photoframe";
import { Label } from "./label";
import { GrafikElement } from "./grafikelement";

export class Layout {

    public id: string;
    public id2: string;
    public terminalid: string;

    public layouttype: string; // breeze, flashbooth, autobooth
    public format: string;
    public previewfile: string;
    public rahmenfile: string;
    public rahmenRadius: number;
    public rahmenBreite: number;
    public width: number;
    public height: number;
    public backgroundfile: string;
    public boxkey: string;

    public backgroundcolor: string;
    public rahmenbackgroundcolor: string;
    public backgroundtype: string;
    public photoBorderWidth: number;
    public photoBorderColor: string;
    public photoBorderRadius: string;

    public photoframes: Photoframe[];

    public labels: Label[];

    public grafikelemente: GrafikElement[];

    // erstellte Grafiken zur Weiterverarbeitung
    public generatedBackground: string;
    public generatedForeground: string;
    public generatedForegroundBreeze: string;
    public generatedForegroundFilename: string;

    public generatedPreview: string;
    public generatedCustomerImage: string;

    public generatedAutoboothfile: string;
    public publicavailable: boolean;
    public category: string;


    public auftragsnummerName: string;
    public auftragsnummerName2: string;
    public datumVeranstaltung: any;
    public datumErstellung: number;
    public showBranding: boolean;
    public showCut: boolean;
    datumVeranstaltungbsDatepicker: string;
    public isStrip: boolean;
    browser: any;
    public email: string;

    public backgroundfileUploaded: any;

    constructor() {
        this.width = 800;
        this.height = 533;
        this.labels = [];
        this.grafikelemente = [];
        this.photoframes = [];
        this.rahmenBreite = 7;
        this.rahmenRadius = 10;
        this.rahmenbackgroundcolor = "#353535";
        this.backgroundcolor = "#5d7256";
        this.backgroundfile = "assets/Hintergruende/querformat/hgq_blumen.png";
        this.rahmenfile = "assets/Hintergruende/querformat/hgq_blumen.png";
        this.publicavailable = false;
        this.category = "privat";
        this.format = "querformat";
        this.layouttype = "autobooth";
        this.showBranding = false;
        this.showCut = false;
        this.datumVeranstaltung = null;
        this.boxkey = null;
        this.isStrip = false;

        var frame = {}; // new Photoframe();
    }
}
