import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NavigationLog } from './navigation-log.interface';
import { FormControl } from '@angular/forms';
import { SessionService } from '../../services/session.service';

@Component({
    selector: 'app-navigation-logs',
    templateUrl: './navigation-logs.component.html',
    styleUrls: ['./navigation-logs.component.css']
})
export class NavigationLogsComponent implements OnInit {
    navigationLogs$: Observable<NavigationLog[]>;
    searchControl = new FormControl('');
    private searchTerm$ = new BehaviorSubject<string>('');

    constructor(
        private firestore: AngularFirestore,
        private sessionService: SessionService
    ) { }

    ngOnInit() {
        this.navigationLogs$ = combineLatest([this.searchTerm$]).pipe(
            switchMap(([searchTerm]) =>
                this.firestore
                    .collection<NavigationLog>('navigation_logs', ref => {
                        let query: any = ref;
                        if (searchTerm) {
                            query = query.orderBy('path')
                                .orderBy('timestamp', 'desc')
                                .where('path', '>=', searchTerm)
                                .where('path', '<=', searchTerm + '\uf8ff');
                        } else {
                            query = query.orderBy('timestamp', 'desc');
                        }
                        return query.limit(300);
                    })
                    .snapshotChanges()
                    .pipe(
                        map(actions => actions.map(a => ({
                            ...a.payload.doc.data(),
                            id: a.payload.doc.id,
                            sessionID: this.sessionService.sessionID
                        })))
                    )
            )
        );

        this.searchControl.valueChanges.subscribe(term => {
            this.searchTerm$.next(term || '');
        });
    }
} 