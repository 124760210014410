import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private _sessionID: string;

    constructor() {
        this._sessionID = this.generateSessionID();
    }

    get sessionID(): string {
        return this._sessionID;
    }

    private generateSessionID(): string {
        return 'session_' + Math.random().toString(36).substr(2, 9);
    }
} 