import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { SessionService } from "../services/session.service";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface LogEntry {
  datetime: string;
  message: string;
  [key: string]: any;
}

@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"]
})
export class LogComponent implements OnInit {
  public logentries: Observable<LogEntry[]>;

  constructor(
    private afs: AngularFirestore,
    private sessionService: SessionService
  ) {
    this.logentries = afs.collection<LogEntry>("log1", ref => ref.orderBy("datetime", "desc").limit(500))
      .valueChanges()
      .pipe(
        map(entries => entries.map(entry => ({
          ...entry,
          sessionId: this.sessionService.sessionID
        })))
      );
  }

  ngOnInit() { }
}
